<div class="animated fadeIn">
  <div class="row settings">
    <div class="col-md-6 settings__title">
      <h1>Subscriptions</h1>
    </div>
    @if (subscriptions().length) {
    <div class="col-md-6 settings__right-element">
      <button type="button" class="d-none d-sm-inline btn btn-primary fw-bold" (click)="manageSubscriptions()"
        [disabled]="isWaitingForAPIResponse()">
        {{pauseButtonText()}}
      </button>
    </div>
    @if (isTemporaryRouteChange()) {
    <app-empty-message [message]="temporaryRouteChangeMessage()"></app-empty-message>
    }
    } @else {
    <app-empty-message [message]="emptyMessage"></app-empty-message>
    <hr>
    }
  </div>

  @if(globalPauseDate().start) {
  <div class="d-grid col-lg-4 col-md-12 mt-2 mb-4">
    <div class="alert alert-warning bg-opacity-50 d-flex align-items-center gap-4 w-auto" role="alert">
      <span class="material-symbols-outlined"> event </span>
      <div>
        <p class="m-0">Your subscriptions have an upcoming pause scheduled:</p>
        <small class="fw-bold">From {{ globalPauseDate().start }} to
          {{ globalPauseDate().end }}</small>
      </div>
    </div>
  </div>
  } @if (subscriptions().length) {
  @if (!isMobile()) {
  <div class="row mt-2 animated fadeIn">
    <div class="col table-responsive">
      <table class="table align-middle">
        <thead>
          <tr>
            <th class="border-0 text-body-tertiary" scope="col">
              Product
            </th>
            <th class="border-0 text-body-tertiary" scope="col">Frequency</th>
            <th class="border-0 text-body-tertiary" scope="col">Quantity</th>
            <th class="border-0 text-body-tertiary" scope="col">
              Next delivery
            </th>
            <th class="border-0 text-body-tertiary" scope="col"></th>
          </tr>
        </thead>
        <tbody class="">
          @for (sub of subscriptions(); track $index) {
          <tr class="fw-semibold">
            <td>
              <a [routerLink]="['/shop/' + sub.product.productUrl]" class="text-decoration-none">
                <h5>{{ sub.product.name }}</h5>
              </a>
              <h6 class="text-success-emphasis">
                {{ sub.product.category.name }}
              </h6>
            </td>
            <td class="td-150">
              <mat-form-field class="w-100 normalized-mat-control">
                <mat-label>Frequency</mat-label>
                <mat-select [(value)]="sub.frequency" [disabled]="isTemporaryRouteChange()" (selectionChange)="
                    triggerUpdate({
                      subscription: sub,
                      event: $event,
                      isQuantity: false
                    })
                  ">
                  @for (freq of frequencies(); track $index) {
                  <mat-option [value]="freq.id">{{ freq.name }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </td>
            <td class="td-150">
              <div class="form-floating has-validation">
                <input class="form-control" [(ngModel)]="sub.quantity" (change)="
                    validateSubscriptionUpdate({
                      subscription: sub,
                      event: $event,
                      isQuantity: true
                    })
                  " placeholder="Quantity" />
                <label for="zipCode">Quantity</label>
              </div>
            </td>
            <td>
              <a class="icon-link icon-link-hover link-underline-primary" href="javascript:void(0)" (click)="triggerUpdate({
                subscription: sub,
                event: $event,
                isQuantity: false,
                isDeliveryChange: true
              })">
                {{ sub.shownNextDelivery }}
                <i class="fas fa-edit"></i>
              </a>
            </td>
            <td class="w-max-content">
              @if (!!sub?.pauseDate?.startDate && sub.isPauseActive) {
              <div class="text-body-tertiary">
                <h6 class="mb-0 fw-bold">Paused subscription</h6>
                <p class="m-0">
                  <small class="fw-normal">{{ sub.pauseDate.startDate }} -
                    {{ sub.pauseDate.endDate }}</small>
                </p>
                <p class="m-0">
                  <small class="fw-bold">Next delivery after pause:</small>
                </p>
                <p class="m-0">
                  <small class="fw-normal">{{
                    sub.pauseDate.nextDeliveryAfterPause
                    }}</small>
                </p>
                <small>
                  <a href="javascript:void(0)" class="h7-bold link-color" (click)="openModalCancel(sub)">
                    Cancel Subscription
                  </a>
                </small>
              </div>

              } @else {
              @if(!!sub.skipDate) {
              <div class="d-grid col-12 justify-content-end">
                <div class="alert alert-warning bg-opacity-50 d-flex align-items-center gap-3 w-max-content px-3"
                  role="alert">
                  <span class="material-symbols-outlined">
                    info
                  </span>
                  <div class="d-grid">
                    <p class="m-0">Skipped delivery day:</p>
                    <small class="fw-bold">{{ sub.skipDate }}</small>
                    <div class="d-flex w-max-content gap-2 justify-content-center align-items-center float-end">
                      <small>
                        <a href="javascript:void(0)" class="h7-bold link-color" (click)="undoSkipLine(sub)">
                          Unskip Week
                        </a>
                      </small>
                      <small>|</small>
                      <small>
                        <a href="javascript:void(0)" class="h7-bold link-color" (click)="openModalCancel(sub)">
                          Cancel
                        </a>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              } @else {
              <div class="d-flex w-max-content gap-2 justify-content-center align-items-center float-end">
                @if (sub.existsInOrder) {
                <small>
                  <a href="javascript:void(0)" class="h7-bold link-color" (click)="openModalSkip(sub)">
                    Skip This Week
                  </a>
                </small>
                <small>|</small>
                }
                <small>
                  <a href="javascript:void(0)" class="h7-bold link-color" (click)="openModalCancel(sub)">
                    Cancel
                  </a>
                </small>
              </div>
              } }
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
  } @else {
  <div class="mt-3 vstack gap-3 animated fadeIn">
    @for (sub of subscriptions(); track $index) {
    <div class="vstack gap-1">
      <!-- Product name and subscription frequency -->
      <p class="m-0">Product</p>
      <div class="hstack gap-2">
        <section class="vstack w-50">
          <!-- <b>{{ sub.product.name }}</b> -->
          <a [routerLink]="['/shop/' + sub.product.productUrl]" class="text-decoration-none">
            <h5>{{ sub.product.name }}</h5>
          </a>
          <small>{{ sub.product.producer }}</small>
        </section>
        <section class="w-50">
          <mat-form-field class="w-100 float-end m-0 normalized-mat-control">
            <mat-label>Frequency</mat-label>
            <mat-select [(value)]="sub.frequency" [disabled]="isTemporaryRouteChange()" (selectionChange)="
                triggerUpdate({
                  subscription: sub,
                  event: $event,
                  isQuantity: false
                })
              ">
              @for (freq of frequencies(); track $index) {
              <mat-option [value]="freq.id">{{ freq.name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </section>
      </div>
      <!-- Subscription quantity -->
      <div class="hstack gap-2">
        <section class="w-50">
          <b>Quantity</b>
        </section>
        <section class="w-50">
          <div class="w-100 m-0 float-end">
            <input placeholder="Quantity" class="form-control" [(value)]="sub.quantity" (change)="
                validateSubscriptionUpdate({
                  subscription: sub,
                  event: $event,
                  isQuantity: true
                })
              " />
          </div>
        </section>
      </div>
      <!-- Next delivery -->
      <div class="hstack gap-2">
        <section class="w-50">
          <b>Next delivery</b>
        </section>
        <section class="w-50 text-end">
          <a class="icon-link icon-link-hover link-underline-primary" href="javascript:void(0)" (click)="triggerUpdate({
            subscription: sub,
            event: $event,
            isQuantity: false,
            isDeliveryChange: true
          })">
            {{ sub.shownNextDelivery }}
            <i class="fas fa-edit"></i>
          </a>
        </section>
      </div>
      <!-- Price -->
      @if (!sub.product.isBundle) {
      <div class="hstack gap-2">
        <section class="w-50">
          <b>Price</b>
        </section>
        <section class="w-50">
          <b class="float-end">{{ sub.price | currency }}</b>
        </section>
      </div>
      }
      <!-- actions -->
      <div class="hstack gap-2 mt-2">
        @if (!!sub?.pauseDate?.startDate && sub.isPauseActive) {
        <div class="text-body-tertiary">
          <h6 class="mb-0 fw-bold">Paused subscription</h6>
          <p class="m-0">
            <small class="fw-normal">{{ sub.pauseDate.startDate }} -
              {{ sub.pauseDate.endDate }}</small>
          </p>
          <p class="m-0">
            <small class="fw-bold">Next delivery after pause:</small>
          </p>
          <p class="m-0">
            <small class="fw-normal">{{
              sub.pauseDate.nextDeliveryAfterPause
              }}</small>
          </p>
          <small>
            <a href="javascript:void(0)" class="h7-bold link-color" (click)="openModalCancel(sub)">
              Cancel Subscription
            </a>
          </small>
        </div>
        } @else {
        @if(!!sub.skipDate) {
        <div class="d-grid col-12">
          <div class="alert alert-warning bg-opacity-50 d-flex align-items-center gap-3 px-3 w-auto" role="alert">
            <span class="material-symbols-outlined">
              info
            </span>
            <div class="d-grid">
              <p class="m-0">Skipped delivery day:</p>
              <small class="fw-bold">{{ sub.skipDate }}</small>
              <div class="d-flex w-max-content gap-2 justify-content-center align-items-center float-end">
                <small>
                  <a href="javascript:void(0)" class="h7-bold link-color" (click)="undoSkipLine(sub)">
                    Unskip Week
                  </a>
                </small>
                <small>|</small>
                <small>
                  <a href="javascript:void(0)" class="h7-bold link-color" (click)="openModalCancel(sub)">
                    Cancel
                  </a>
                </small>
              </div>
            </div>
          </div>
        </div>
        } @else {
        <section class="w-50">
          <a href="javascript:void(0)" class="fw-bold h7-bold link-color" (click)="openModalCancel(sub)">
            Cancel
          </a>
        </section>
        @if (sub.existsInOrder) {
        <section class="w-50">
          <a href="javascript:void(0)" class="float-end h7-bold link-color" (click)="openModalSkip(sub)">
            Skip This Week
          </a>
        </section>
        }
        } }
      </div>
    </div>
    <hr class="mt-2 mb-0" />
    }
    <button type="button" class="d-sm-none btn btn-primary fw-bold" (click)="manageSubscriptions()">
      {{ globalPauseDate().start ? "REMOVE PAUSE" : "PAUSE SUBSCRIPTIONS" }}
    </button>
  </div>

  }
  }
</div>

<!-- PRE ORDERS -->
@if(preorders().length) {
<div class="col-md-6 settings__title mt-2 animated fadeInLeft">
  <h1>Pre Orders</h1>
</div>

<app-empty-message [width100]="true" [message]="preOrdersDisclaimer()"></app-empty-message>

<section>
  @for (item of preorders(); track $index) {
  <app-pre-ordered-product [product]="item"></app-pre-ordered-product>
  }
</section>
}

<!-- PRE ORDERS: ENDS -->