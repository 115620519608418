<div [classList]="containerClassToAplly() + (hasGlobalMessages() ? '-global-message' : '')">

  <app-notification></app-notification>

  @defer(on timer(0.5s)) {
  <app-global-message class="sticky-top animated fadeInDown"></app-global-message>
  }

  @if (showAppHeader()) {
  <app-header class="sticky-top header"></app-header>
  }

  @if (isShopComponent()) {
  <app-shop-slider-banner></app-shop-slider-banner>
  }

  @if (isShopComponent()) {
  <app-shop-bundle-list [class.cart-preview-showing]="showAppCartPreview()"></app-shop-bundle-list>
  }

  @if (isShopComponent()) {
  <app-shop-header [class.cart-preview-showing]="showAppCartPreview()"></app-shop-header>
  }

  @if (showAppCartPreview()) {
  <app-cart-preview class="cart-preview" [class.cart-preview-height]="!hasGlobalMessages()"
    [class.cart-preview-height-global-message]="hasGlobalMessages()" [class.offcanvas-opened]="isSidebarOpen()">
  </app-cart-preview>
  }

  <div class="main-container" appScrollToTop [class.header-showing]="showAppHeader() && !hasGlobalMessages()"
    [class.header-showing-global-message]="showAppHeader() && hasGlobalMessages()"
    [class.header-showing-hidden-sub-nav]="!shouldShowCategoriesBar() && !hasGlobalMessages()"
    [class.header-showing-hidden-sub-nav-global-message]="!shouldShowCategoriesBar() && hasGlobalMessages()"
    [class.cart-preview-showing]="showAppCartPreview()" [class.mobile-main-container]="showMobileFooter()"
    [class.with-shop-nav-only]="isShopComponent() && isShopBannerHidden()"
    [class.fadeInDown_ShopContainer]="isShopComponent() && !isShopBannerHidden()"
    [class.hidden-mobile-footer]="!showMobileFooter()">
    <router-outlet />
  </div>

  @if (showJustAddedProduct()) {
  <app-product-added-notification class="cart-preview just-added-product-notification">
  </app-product-added-notification>
  }

  @if (showMobileFooter()) {
  <app-mobile-footer-menu></app-mobile-footer-menu>
  }

  @if (loadingService.isShowGlobalLoading()) {
  <app-loading></app-loading>
  }

</div>