import { Injectable, WritableSignal, inject, signal, } from '@angular/core';
import { FilterTypes } from './search-bar/search-bar.types';
import { ProductsService } from '../../product/products.service';
import { SignalsStoreService } from '../signals-store.service';
import { RequestHandlerParams } from '../types/api-service.types';
import { ApiService } from '../api.service';
import { filter, tap } from 'rxjs';
import { FilterParams } from '../../product/product.types';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private productService = inject(ProductsService);
  private signalsStoreService = inject(SignalsStoreService);
  private apiService = inject(ApiService);

  searchBarContent: WritableSignal<string> = signal('');
  displaySearchBar: WritableSignal<boolean> = signal(false);
  triggerParam: WritableSignal<boolean> = signal(false);

  clearFilters(hideSearchBar: boolean = true) {
    this.displaySearchBar.set(!hideSearchBar);
    this.signalsStoreService.filterByProducer.set(null);

    // If the filters are currently cleaned, then, return.
    if (!this.signalsStoreService.isFiltering()) return;

    this.searchBarContent.set('');
    this.setFiltering(false);
    this.signalsStoreService.setFilterChipRemoved('');
    this.signalsStoreService.setFilters(new Map());
    this.productService.getAllProductsResume(true);
  }

  getFrequencies() {
    const params: RequestHandlerParams = {
      method: 'GET',
      endpoint: '/subscriptions/frequencies',
      apiV3: true,
    };

    this.apiService
      .handleRequest(params)
      .pipe(
        filter((response: any) => response?.length),
        tap((response: any) =>
          this.signalsStoreService.frequencies.set(response)
        )
      )
      .subscribe();
  }

  setFiltering(fitlering: boolean) {
    this.signalsStoreService.isFiltering.set(fitlering);
  }

  updateSearchBarDisplay(clearTagsFilter: boolean = false) {
    this.displaySearchBar.update((current: boolean) => !current);
    if (clearTagsFilter) {
      this.productService.tagsSignal.update(tags => {
        tags.map(tag => tag.isChecked.set(false));
        return tags
      });
      this.productService.filterBySelectedTags();
    }
  }

  producerRedirection() {
    if (this.signalsStoreService.filterByProducer()) {
      this.updateSearchBarDisplay();
      this.signalsStoreService.isFiltering.set(true);
      const filterParams: FilterParams = {
        type: FilterTypes.producers,
        values: [this.signalsStoreService.filterByProducer()],
      };
      this.productService.getProductsByFilter([filterParams]);
    }
  }
}
