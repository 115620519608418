import { Component, AfterViewInit, inject, signal, WritableSignal, computed, Inject, PLATFORM_ID, ChangeDetectorRef, viewChild, Signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStep, MatStepper, MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, provideNativeDateAdapter } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AddressService, NavegoAddressResponse } from '../../shared/address.service';
import { PersonalInformation } from './signup.types';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AccountService } from '../../shared/account.service';
import { Router, RouterLink } from '@angular/router';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CUSTOM_DATE_FORMATS } from '../../shared/constants/date-formtats';
import { LocalStorageService } from '../../shared/local-storage.service';
import { SignalsStoreService } from '../../shared/signals-store.service';
import { Session } from '../../shared/types/session.type';
import { LOCALSTORAGE_KEYS } from '../../shared/constants/databases';
import { ArrayOptionsItem, NewAccountPayload, AccountSettings } from '../../shared/types/account.types';
import { PaymentMethodService } from '../../settings/billing/payment-method/payment-method.service';
import { MatRadioModule } from '@angular/material/radio';
import { PersonalInformationSignupComponent } from './personal-information-signup/personal-information-signup.component';
import { CheckAddressSignupComponent } from './check-address-signup/check-address-signup.component';
import { PaymentMethodSignupComponent } from './payment-method-signup/payment-method-signup.component';
import { ProfileService } from '../../settings/account/profile/profile.service';
import { finalize } from 'rxjs';
import { ModalContentService } from '../../shared/modal-content/modal-content.service';
import { ModalContentTypes } from '../../shared/constants/modal-content-types';
import { ApiResponse } from '../../shared/common/types';
import { HTTP_STATUS_CODES } from '../../shared/constants/errors';

@Component({
  selector: 'app-signup',
  providers: [provideNativeDateAdapter(),
  { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS.MONTH_AND_YEAR },
    PaymentMethodService,
    ProfileService
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    RouterLink,
    MatRadioModule,
    CheckAddressSignupComponent,
    PersonalInformationSignupComponent,
    PaymentMethodSignupComponent,
  ],
  templateUrl: './signup.component.html',
  styleUrl: './signup.component.scss'
})

export class SignupComponent implements AfterViewInit {

  //#region Services

  private signalsStoreService = inject(SignalsStoreService);

  private addressService = inject(AddressService);

  localStorageService = inject(LocalStorageService);

  accountService = inject(AccountService);

  cdr = inject(ChangeDetectorRef);

  #router = inject(Router);

  #modalContentService = inject(ModalContentService);

  //#endregion

  //#region Properties

  matStepper = viewChild.required<MatStepper>(MatStepper);

  personalInformationComponent: Signal<PersonalInformationSignupComponent> = viewChild.required('personalInformationComponent');

  firstStep = viewChild.required<MatStep>('firstStep');

  isLimitedUser = computed(() => this.signalsStoreService.isLimitedUser());

  formValidityPersonalInformation: boolean | undefined;

  formValidityShoppingPreferences: boolean | undefined;

  formValidityPaymentMethod: boolean | undefined;

  deliveryAddressInformation: any;

  deliverySelectedDay: any;

  deliverySelectedTimeWindow: any;

  deliveryOptionSelected: any;

  personalInformationData: any;

  shopforValue: any;

  dietaryRestrictionsValues: any;

  paymentMethodStepControl: FormControl = new FormControl(true);

  personalInformation: WritableSignal<PersonalInformation | null> = signal(null);

  skipPaymentMethodStep: boolean = false;

  isLoadingContent: any = this.addressService.isLoading;

  isWaitingResponse: any = signal(false);

  shouldDisableCreateClientButton = signal(false);

  shouldSetUpSettings: boolean = true;

  subscriptionCard: WritableSignal<any[]> = signal([]);

  logoVersionNumber = computed(() => this.signalsStoreService.logoVersionNumber());

  hasGlobalMessages = computed(() => !!this.signalsStoreService.globalMessages().length);

  //#endregion

  //#region Constructor

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  ngAfterViewInit() {
    if (!isPlatformBrowser(this.platformId)) return;

    const signupSelectedMembership: any = this.localStorageService.get(LOCALSTORAGE_KEYS.SIGNUP_SELECTED_MEMBERSHIP);
    if (signupSelectedMembership?.id)
      this.deliveryOptionSelected = signupSelectedMembership;

    const sessionStored: Session = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) as any
    const hasPaymentMethod = !!(sessionStored)?.settings?.hasPaymentMethod;
    const stepToLoad = this.signalsStoreService.hasSession() && !hasPaymentMethod ? 2 : 0;

    // Load first step or payment method step if hasSession
    if (stepToLoad > 0) {
      for (let index = 0; index < stepToLoad; index++) {
        this.matStepper().steps.toArray()[index].completed = true;
      }

      this.matStepper().selectedIndex = stepToLoad;
    }

    this.cdr.detectChanges();
  }

  //#endregion

  //#region Methods

  continue() {

    this.firstStep().completed = true;
    this.matStepper().next();

    const { selectedIndex } = this.matStepper();

    if (selectedIndex === 1) {
      this.firstStep().editable = false;
      if (this.shouldSetUpSettings) {
        this.getAccountSettings();
        this.shouldSetUpSettings = false;
      }
    }
  }

  createNewClient() {
    if (this.shouldDisableCreateClientButton()) return;
    this.shouldDisableCreateClientButton.set(true);
    const payload = this.setUpNewClientPayload();
    if (!payload) return;

    this.accountService
      .create(payload)
      .pipe(
        finalize(() => this.shouldDisableCreateClientButton.set(false))
      )
      .subscribe({
        next: (res: ApiResponse<Session>) => {
          const { data, status } = res;
          if (status === HTTP_STATUS_CODES.CONFLICT)
            return this.#showModalEmailAlreadyInUse(payload.accountInfo.email);
          if (data?.isTimeSlotCapacityFull) {
            this.firstStep().editable = true;
            this.matStepper().previous();
          } else if (data) {
            this.matStepper().next();
            this.shouldDisableCreateClientButton.set(false);
          }
        }
      });
  }

  #showModalEmailAlreadyInUse(email: string) {
    this.#modalContentService.openModal(ModalContentTypes.CONFIRMATION, {
      title: 'Email already in use',
      textContent: `Hey! Looks like you already have an account with us. Would you like to login with <b><i>${email}</i></b>?`,
      cancelButtonText: 'Try another email',
      confirmButtonText: 'Click here to login',
    }).closed
      .subscribe((res: { confirm: boolean } | null) => {
        if (!res?.confirm) return this.personalInformationComponent().clearEmailField();
        this.localStorageService.set(LOCALSTORAGE_KEYS.PRELOADED_EMAIL, email);
        this.#router.navigate(['/login']);
      })
  }

  private getAccountSettings() {
    this.accountService.getAccountSettings().subscribe({
      next: (accountSettings: AccountSettings | null) => {
        if (accountSettings) {
          this.setUpAccountSettings(accountSettings);
        }
      }
    });
  }

  private setUpAccountSettings(accountSettings: AccountSettings) {
    this.personalInformation.set({
      contactPerson: {
        email: null,
        firstName: null,
        lastName: null,
        phone: null,
      },
      dietaryRestrictions: accountSettings.dietaryPreferences?.map((preference: ArrayOptionsItem) => {
        return { ...preference, completed: false };
      }) || [],
      peopleShoppingFor: accountSettings.peopleShoppingFor || [],
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      phone: '',
      referralSource: '',
      advertisement: '',
      shopfor: null,
      receiveNewsletter: true,
      receiveText: true
    });
  }

  private setUpNewClientPayload(): NewAccountPayload | null {
    try {
      const { firstName, lastName, phone, email, password, birthday, advertisement, dietaryRestrictions = this.dietaryRestrictionsValues, contactPerson: contact, receiveNewsletter, receiveText, receiveTextDelivery } = this.personalInformationData() || {};
      const { additionalAddress: additional, zipCode: zip, deliveryNotes: noteDriver } = this.deliveryAddressInformation()
      const { street, zoneId, zoneName, city, state: stateCode, latitude, longitude } = this.addressService.navegoAddress() as NavegoAddressResponse || {}
      const res = {
        accountInfo: {
          dietaryPreferences: dietaryRestrictions?.filter((value: any) => value.completed).map((value: any) => { return { id: value.id, name: value.name } }) || null,
          email,
          firstName,
          lastName,
          noteDriver,
          password,
          phone,
          birthday
        },
        settings: {
          receiveNewsletter,
          receiveText,
          receiveTextDelivery,
          delivery: {
            type: this.deliveryOptionSelected.id,
            dayId: this.deliverySelectedDay.id,
            timeWindowId: this.deliverySelectedTimeWindow === -1 ? null : this.deliverySelectedTimeWindow
          },
          peopleShoppingForId: this.shopforValue
        },
        contact
      } as NewAccountPayload

      if (this.deliveryOptionSelected.configuration.requireAddress) {
        res.address = {
          additional: additional,
          city: city,
          latitude: latitude,
          longitude: longitude,
          stateCode: stateCode,
          street: street,
          zip: zip,
          zoneId: zoneId || this.deliverySelectedDay.zoneId
        }
      } else {
        res.address = {
          zoneId: this.deliverySelectedDay.zoneId
        }
      }

      return res;
    } catch (error) {
      console.log('error >>>', error);
      return null;
    }
  }

  showWelcomeModal() {

    this.#modalContentService
      .openModal(ModalContentTypes.CONFIRMATION, {
        title: 'Welcome to The Produce Box',
        textContent: `We are thrilled to have you on board.<br /><br /> Quick reminder: The Menu opens on Friday morning at 9am and closes Sunday at 9pm.`,
        confirmButtonText: 'Start shopping'
      })
      .closed
      .subscribe(() => this.#router.navigate([`/welcome`]));
  }

  //#endregion

  // updateCreatedClient() {
  //   if (this.matStepper().selectedIndex === 2 && !this.deliveryOptionSelected.configuration.requirePaymentMethod
  //   ) {
  //     this.paymentMethodStepControl.disable();
  //     this.skipPaymentMethodStep = true;
  //     this.matStepper().selectedIndex = 3;
  //     this.getSignupProducts();
  //     this.matStepper().next();
  //   } else {
  //     this.accountService.setMustFetchStates();
  //   }
  //   const payload = this.setUpUpdateClientPayload();
  //   this.profileService.putPreferences(payload).pipe(
  //     tap(() => {
  //       this.matStepper().next();
  //     })
  //   ).subscribe()
  // }

  // private setUpUpdateClientPayload(): any | null {
  //   try {
  //     const { dietaryRestrictions = this.dietaryRestrictionsValues } = this.personalInformationData() || {};
  //     return {
  //       accountInfo: {
  //         dietaryPreferences: dietaryRestrictions?.filter((value: any) => value.completed).map((value: any) => { return { id: value.id, name: value.name } }) || null,
  //       },
  //       settings: {
  //         peopleShoppingForId: this.shopforValue
  //       }
  //     } as any
  //   } catch (error) {
  //     console.log('error >>>', error);
  //     return null;
  //   }
  // }

  // private getSignupProducts() {
  //   this.productsService.getSignupProducts().pipe(
  //     tap((response) => {
  //       this.subscriptionCard.set(response.data)
  //     })
  //   ).subscribe()
  // }
}
