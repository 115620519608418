import { Component, computed, inject, model, signal, Signal, WritableSignal } from '@angular/core';
import { ProductsService } from '../../../product/products.service';
import { Tag } from '../../../product/product.types';
import { handleImageError } from '../../common/utils';
import { ResolutionService } from '../../resolution.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-tags-filter',
  imports: [FormsModule, ReactiveFormsModule, MatCheckboxModule],
  templateUrl: './tags-filter.component.html',
  styleUrl: './tags-filter.component.scss'
})
export class TagsFilterComponent {

  #productsService = inject(ProductsService);
  #resolutionService = inject(ResolutionService);

  tags: WritableSignal<Tag[]> = this.#productsService.tagsSignal;
  isMobile: Signal<boolean> = computed(() => this.#resolutionService.isMobile());
  isContentLoaded: Signal<boolean> = computed(() => this.#productsService.isLoaded())

  handleImageError = handleImageError;

  filterByTags() {
    this.#productsService.filterBySelectedTags(true);
  }


}
