<!--Main Navigation-->
<header class="bg-white top-header" [class.isShopNavigationDisplayed]="isShopComponent()"
  [ngClass]="{'top-header--hidden-sub-nav': !shouldShowCategoriesBar()}">
  <nav class="navbar navbar-expand-lg navbar-light nav-main h-100">
    <!-- Container wrapper -->
    <!-- Toggle button -->
    <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
      aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
      <span class="material-symbols-outlined">menu</span>
    </button>

    <!-- Navbar brand -->
    <a class="navbar-brand animated fadeInLeft" [class.mx-auto]="isCustomBoxSignupFlow()" [routerLink]="['/home']"
      href="#">
      <img src="assets/images/logo.webp?v={{logoVersionNumber()}}" alt="The Produce Box" loading="lazy" />
    </a>

    @if (isShopComponent()) {
    <div class="d-lg-none d-flex gap-3">

      <app-tags-filter></app-tags-filter>

      <button class="search-toggler show-on-shop" (click)="toggleSearchMobile()">
        <span class="material-symbols-outlined">search</span>
      </button>
    </div>
    }

    <div class="offcanvas offcanvas-start d-lg-none mobile-menu" [class.hidden-mobile-footer]="!isShowingMobileFooter()"
      data-bs-backdrop="true" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
      <div class="offcanvas-body">
        <ul class="navbar-nav justify-content-end flex-grow-1">
          @for (page of landingPages(); track $index) {
          <li class="nav-item">
            <a class="nav-link" [routerLink]="[page.route]" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar">{{page.name}}</a>
            @if (page.submenu) {
            <ul class="sub-menu">
              @for (item of page.submenu; track $index) {
              @if(item.url) {
              <li><a class="nav-link" [href]="item.url">{{item.name}}</a></li>
              }
              @else {
              <li><a class="nav-link" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
                  aria-controls="offcanvasNavbar" [routerLink]="[item.route]">{{item.name}}</a></li>
              }
              }
            </ul>
            }
          </li>
          }
        </ul>
        <hr>

        @if (!signalsStoreService.hasSession()) {
        <ul class="navbar-nav justify-content-end flex-grow-1 gap-2">
          <li>
            <button routerLink="/login" class="btn btn-static btn-outline-primary w-100" type="button">Log In</button>
          </li>
          <li>
            <button routerLink="/signup" class="btn btn-static btn-primary w-100" type="button">
              Sign Up
            </button>
          </li>
        </ul>
        } @else {
        <ul class="navbar-nav justify-content-end flex-grow-1 mobile-menu__sub">
          @for (parent of settingsPages(); track $index) {
          <p class="mobile-menu__title">
            <span class="icon material-symbols-outlined">
              {{parent.icon}}
            </span>
            <span>{{parent.name}}</span>
          </p>
          @for (page of parent.children; track $index) {
          <li>
            <a class="nav-link" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar" [routerLink]="[page.route]">{{page.name2 &&
              !(session()?.settings?.requireAddress) ? page.name2 : page.name}}</a>
          </li>
          }
          }
          <hr>
          <li>
            <span class="nav-link with-action" (click)="logOut()">
              <p class="m-0">{{ userName() }}</p>
              <span class="material-symbols-outlined">login</span>
            </span>
          </li>
        </ul>
        }

      </div>
    </div>

    <!-- Collapsible wrapper -->
    <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
      <!-- Center elements -->
      <ul class="navbar-nav mx-auto justify-content-center nav-main animated fadeInDown">
        @for (page of landingPages(); track $index) {
        @if (!page.mobile) {
        <li class="nav-item" [class.has-sub-menu]="page.submenu">
          <a class="nav-link fw-semibold" [routerLink]="[page.route]">{{page.name}}</a>
          @if (page.submenu) {
          <ul class="sub-menu">
            @for (item of page.submenu; track $index) {
            @if(item.url) {
            <li><a class="nav-link" [href]="item.url">{{item.name}}</a></li>
            }
            @else {
            <li><a class="nav-link" [routerLink]="[item.route]">{{item.name}}</a></li>
            }
            }
          </ul>
          }
        </li>
        }
        }
      </ul>

      @defer (on viewport; when isSessionLoaded()) {
      @if (!signalsStoreService.hasSession()) {
      <div class="navbar-nav justify-content-end animated fadeIn">
        <button routerLink="/login" class="btn btn-static btn-outline-secondary me-2" type="button">Log In</button>
        <button routerLink="/signup" class="btn btn-static btn-secondary" type="button"> Sign Up
        </button>
      </div>
      } @else {
      <!-- Right elements -->
      <ul class="navbar-nav justify-content-end animated fadeIn">
        <!-- Icon -->
        @for (element of rightElements(); track $index) {
        <li class="nav-item nav-item--icon">
          <span class="nav-link fw-semibold cursor-pointer" (click)="navigateTo(element)" [ngbTooltip]="element.tooltip"
            placement="bottom">
            <div class="position-relative">
              <span class="material-symbols-outlined" [attr.data-nav]="element.id">
                {{element.icon}}
              </span>
              @if (element.showBadge && orderProductsQuantity()) {
              <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill quanity-badge"
                [attr.data-nav]="element.id">
                {{ orderProductsQuantity() }}
                <span class="visually-hidden">unread messages</span>
              </span>
              }
            </div>
          </span>
        </li>
        }
        <div class="vr border border-primary mx-2"></div>
        <li class="nav-item dropdown d-flex align-items-center">
          <a class="nav-link fw-semibold dropdown-toggle dropdown-toggle-split" href="#" role="button"
            data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
            {{ userName() }}
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            @for (parent of settingsPages(); track $index) {
            <h6 class="dropdown-header">
              <span class="icon material-symbols-outlined">
                {{parent.icon}}
              </span>
              <span>{{parent.name}}</span>
            </h6>
            @for (page of parent.children; track $index) {
            <li>
              <a class="dropdown-item" [routerLink]="[page.route]">{{page.name2 &&
                !(session()?.settings?.requireAddress) ? page.name2 : page.name}}</a>
            </li>
            }
            }
            <div class="dropdown-divider"></div>
            <li (click)="logOut()">
              <span class="nav-link with-action cursor-pointer">
                <span class="material-symbols-outlined rotate-icon">
                  login
                </span>
                <span>Log out</span>
              </span>
            </li>
          </ul>
        </li>
      </ul>
      }
      } @placeholder {
      <div class="placeholder-glow col-1 animated fadeIn">
        <li class="placeholder col-12 bg-secondary"></li>
      </div>
      }

    </div>
    <!-- } -->


    @if (isShopComponent()) {
    <!-- Mobile navbar -->
    <nav class="navbar navbar-expand-lg navbar-dark nav-category nav-category--mobile animated fadeIn">
      @if (isShowingSearchMobile()) {
      <div class="category-search--mobile">
        <app-search-bar></app-search-bar>
      </div>
      } @else {
      <div class="d-flex flex-row w-100 gap-3">
        <!-- Category -->
        <mat-form-field class="w-100">
          <mat-label>Category</mat-label>
          <mat-select #matSelect [disabled]="isContentLoading()"
            [value]="signalsStoreService.selectedCategory()?.id || null">
            @if (sidebarService.sidebar().length) {
            @for (item of sidebarService.sidebar(); track $index) {
            <mat-option [value]="item.id" (click)="triggerCategoryInfoFetch(item, false)">{{ item.name }}</mat-option>
            }
            }
          </mat-select>
        </mat-form-field>
        <!-- Subcategory -->
        <mat-form-field class="w-100">
          <mat-label>Subcategory</mat-label>
          <mat-select #matSelect [disabled]="isContentLoading()" [value]="subcategorySelected">
            @if (signalsStoreService.selectedCategory()?.sublist?.length) {
            @for (item of signalsStoreService.selectedCategory().sublist; track $index) {
            <mat-option [value]="item.id" (click)="goToSection(item)">{{ item.name }}</mat-option>
            }
            }
          </mat-select>
        </mat-form-field>
      </div>
      }
      @if (isShowingSearchMobile()) {
      <div class="category-menu show-on-shop">
        <button type="button" class="category-menu__icon" (click)="toggleSearchMobile()">
          <span class="material-symbols-outlined">close</span>
        </button>
      </div>
      }
    </nav>
    }
  </nav>




</header>
<!--Main Navigation-->
<!-- } -->