import { Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, QueryList, Signal, ViewChild, ViewChildren, afterNextRender, computed, inject, signal } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { SignalsStoreService } from '../shared/signals-store.service';
import { SidebarComponent } from '../shared/sidebar/sidebar.component';
import { ProductsService } from '../product/products.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { auditTime, bufferWhen, filter, map, mergeMap, of, scan, startWith, Subject, switchMap, tap, timer } from 'rxjs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MEDIUM } from "../../scss/responsive/responsive";
import { HeaderService } from '../shared/header/header.service';
import { ResolutionService } from '../shared/resolution.service';
import { EmptyMessageComponent } from '../shared/empty-message/empty-message.component';
import { SearchBarComponent } from '../shared/header/search-bar/search-bar.component';
import { MenuService } from '../shared/menu.service';
import { FilteringTagsComponent } from '../shared/shop-header/filtering-tags/filtering-tags.component';
import { ProductCardV2Component } from "../shared/product-card-v2/product-card-v2.component";
import { ProductResume, Product } from '../product/product.types';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '../shared/order.service';
import { MatChipsModule } from '@angular/material/chips';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrl: './shop.component.scss',
  imports: [
    CommonModule,
    SidebarComponent,
    NgbModule,
    MatProgressBarModule,
    EmptyMessageComponent,
    SearchBarComponent,
    EmptyMessageComponent,
    FilteringTagsComponent,
    ProductCardV2Component,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatChipsModule,
  ]
})
export class ShopComponent implements OnInit, OnDestroy {

  @ViewChildren('subcategory')
  subcategories!: QueryList<ElementRef>;

  @ViewChildren('subcategorySon')
  subcategoriesSon!: QueryList<ElementRef>;

  #productsService = inject(ProductsService);
  #headerService = inject(HeaderService);
  #resolutionService = inject(ResolutionService);
  #menuService = inject(MenuService);
  #activatedRoute = inject(ActivatedRoute);
  #orderService = inject(OrderService);

  signalsStore = inject(SignalsStoreService);

  isShowingShopFilters = computed(() => this.#headerService.displaySearchBar());
  isFilteringByTags = computed(() => this.#checkTagsFilter());

  lastCategory: string | null = null;
  lastSubcategory: string | null = null;
  lastSubcategorySon: string | null = null;

  productsSignal: Signal<ProductResume[]> = computed(() => {
    const sortedProducts = this.#productsService.productsSignal();
    this.lastSubcategory = null;
    this.lastCategory = null;

    return sortedProducts.map((product: any) => {
      if (product.category.name !== this.lastCategory) {
        product.showCategory = true;
        this.lastCategory = product.category.name;
      } else {
        product.showCategory = false;
      }

      if (product.subcategory.name !== this.lastSubcategory) {
        product.showSubcategory = true;
        this.lastSubcategory = product.subcategory.name;
      } else {
        product.showSubcategory = false;
      }

      if (product.subcategorySon && product.subcategorySon.name !== this.lastSubcategorySon) {
        product.showSubcategorySon = true;
        this.lastSubcategorySon = product.subcategorySon.name;
      } else {
        product.showSubcategorySon = false;
      }

      return product as ProductResume;

    }) as ProductResume[];
  });

  marketStatus = computed(() => this.signalsStore.marketStatus());
  closedMarket = computed(() => {
    const im = this.isMobile();
    const ms = this.marketStatus();
    return {
      title: !ms.isOpen && im ?
        `Our Online Market Opens ${ms?.openingDay?.toUpperCase() || ''} Morning!` :
        `Our Online Market Opens ${ms?.openingDay?.toUpperCase() || ''} Morning!`,
      legend: `We're busy sourcing next week's availability! Check back ${ms?.openingDay || ''} at ${ms?.openingHour || ''} am to begin shopping.`
    };
  })

  isContentLoading = computed(() => this.#productsService.isLoading());
  isContentLoaded = computed(() => this.#productsService.isLoaded());

  isMobile = computed(() => this.#resolutionService.isMobile());

  emptyMessage = 'No products were found.';
  hasGlobalMessages = computed(() => !!this.signalsStore.globalMessages().length);

  subscribedBundles = computed(() => this.#orderService.getBundlesLikeSubscriptions());
  globalMessagesDisplayed = computed(() => this.signalsStore.globalMessages());

  // This is for shop banner in and out animations:
  @ViewChild('layoutShopMain') layoutShopMain!: ElementRef;
  #lastScrollTop = 0;
  isShopBannerHidden = computed(() => this.signalsStore.isShopBannerHidden());

  numberBundleSubscribed = computed(() => this.signalsStore.numberBundleSubscribed());

  productDetails = signal<Map<number, Product>>(new Map()); // Cache of product details
  #visibleProducts$ = new Subject<number[]>(); // IDs of products that are visible in the viewport


  filters = computed(() => this.signalsStore.getFilters());

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    afterNextRender(() => {
      const isMobile = window.innerWidth <= MEDIUM;
      if (isMobile) this.signalsStore.isSidebarOpen.set(false);
    });
  }

  ngOnInit(): void {
    if (this.signalsStore.filterByProducer()) {
      setTimeout(() => {
        this.#headerService.displaySearchBar.set(true);
      }, 500);
    }
    else
      this.#getProductsResolverData();

    this.#setupBatchRequests();
  }

  #getProductsResolverData() {
    this.#activatedRoute.snapshot.data['data'].subscribe((data: any) => {
      this.#productsService.setUpProductsResponse(data.data);

      if (isPlatformBrowser(this.platformId)) {
        this.#gotoSelectedProduct();
      }
    });
  }

  #gotoSelectedProduct() {
    const selectedProductId = localStorage.getItem('selectedProductId');
    if (selectedProductId != null) {
      const scrollToTop = document.getElementById(selectedProductId!.toString());
      if (scrollToTop) {

        const parent = document.getElementById('layoutShopMain');
        parent?.scrollTo({
          top: (scrollToTop.offsetTop - 100),
          left: 0,
          behavior: 'instant'
        });

        localStorage.removeItem('selectedProductId');
      }
    }
  }

  toggleSidebar() {
    this.signalsStore.isSidebarOpen.update((currentValue) => !currentValue);
  }

  ngOnDestroy(): void {
    this.#headerService.clearFilters(true);
    this.signalsStore.setIsInShopComponent(false);
  }


  onScroll(event: any) {

    // Nothing products in shop
    this.#shopBannerDisplay(event);

    if (!this.subcategories.length)
      return;

    const contentY = document
      .getElementsByClassName('layout-shop__main')[0]
      ?.getBoundingClientRect().y ?? 0;

    if (!contentY)
      return;

    // Search the element most near when scroll is down.

    let categoryId = null;
    let subCategoryId = null;
    let subCategorySonId = null;

    // Validate if the scroll is bottom

    let element: ElementRef<any> | undefined;
    const isBottom = event.srcElement.scrollHeight - event.srcElement.scrollTop <= event.srcElement.clientHeight + 5;

    if (isBottom) {
      element = this.subcategories.toArray().reverse()[0];
      if (+(element?.nativeElement?.getAttribute('sub-category-son-id') || 0) !== -1)
        element = this.subcategoriesSon.toArray().reverse()[0];
    }
    else {

      element = this.subcategoriesSon
        .find(x => {
          const diffY = x.nativeElement.getBoundingClientRect().y - contentY;

          if (diffY < 0) return false;
          return diffY >= -300 && diffY <= 100;
        });

      if (!element) {
        element = this.subcategories
          .find(x => {
            const diffY = x.nativeElement.getBoundingClientRect().y - contentY;

            if (diffY < 0) return false;
            return diffY >= -300 && diffY <= 100;
          });
      }

      if (!element) {

        // Search the last element most near when scroll is up.

        const sub = this.subcategories
          .toArray()
          .reverse()
          .map(x => {
            const diffY = x.nativeElement.getBoundingClientRect().y - contentY;
            return {
              ...x,
              diffY
            };
          })
          .find(x => {
            return x.diffY < 0;
          });

        const subson = this.subcategoriesSon
          .toArray()
          .reverse()
          .map(x => {
            const diffY = x.nativeElement.getBoundingClientRect().y - contentY;
            return {
              ...x,
              diffY
            };
          })
          .find(x => {
            return x.diffY < 0;
          });


        const absSub = Math.abs(sub?.diffY || 0)
        const absSubSon = Math.abs(subson?.diffY || 0);

        const absSubDiff = Math.abs(absSub - 200);
        const absSubSonDiff = Math.abs(absSubSon - 200);

        element = absSubDiff >= absSubSonDiff ? subson : sub;

      }
    }

    if (!element)
      return;

    // Get the category and subcategory attibutes

    categoryId = +element.nativeElement.getAttribute('category-id');
    subCategoryId = +element.nativeElement.getAttribute('sub-category-id');
    subCategorySonId = +element.nativeElement.getAttribute('sub-category-son-id');

    // If yet not change of category, don't do nothing.

    if (this.signalsStore.categoryInViewPort()?.categoryId === categoryId &&
      this.signalsStore.categoryInViewPort()?.subCategoryId === subCategoryId &&
      this.signalsStore.categoryInViewPort()?.subCategorySonId === subCategorySonId)
      return;

    // Emit the new visible category.

    this.signalsStore
      .categoryInViewPort
      .set({
        categoryId,
        subCategoryId,
        subCategorySonId
      });
  }

  #shopBannerDisplay(event: any) {
    const currentScroll = this.layoutShopMain.nativeElement.scrollTop;

    const element = event.target as HTMLElement;

    const windowHeight = document.documentElement.scrollHeight;

    // Height of: header, banner and shop toolbar
    const headerheights = 350;
    const mobileFooterHeight = 80;
    const globalMessageHeight = this.globalMessagesDisplayed()?.length ? 50 : 0;
    const shopBannerListHeight = 90 * (this.subscribedBundles()?.length || 0);

    const total = element.scrollHeight + headerheights + (this.isMobile() ? mobileFooterHeight : 0) + shopBannerListHeight + globalMessageHeight;

    // Verificar si tiene scroll vertical activo
    const hasVerticalScroll = total > windowHeight;

    if (!hasVerticalScroll) return this.signalsStore.isShopBannerHidden.set(false);

    if (currentScroll > this.#lastScrollTop) {
      // Scrolling down - hide the banner
      this.signalsStore.isShopBannerHidden.set(true);
    } else {
      // Scrolling up - show the banner
      this.signalsStore.isShopBannerHidden.set(false);
    }

    // this.#lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // Prevents negative scrolling
  }

  getSubCategoryBanner(categoryId: number, subCategoryId: number): { banner: string, url: string } | null {

    const category = this.#menuService.menu().find(x => x.id === categoryId);

    if (!category) return null;

    const subcategory = category.subCategories
      .find((x: any) => x.id === subCategoryId);

    const banner = subcategory.banners[this.isMobile() ? 'mobile' : 'desktop'];

    if (!banner) return null;

    return {
      banner,
      url: subcategory.banners.url
    };
  }

  #checkTagsFilter() {
    return this.#productsService.tagsSignal().some(tag => tag.isChecked());
  }

  #setupBatchRequests() {
    const noActivity$ = this.#visibleProducts$.pipe(
      switchMap(() => timer(100).pipe(map(() => true))), // Si no hay nuevos valores en 500ms → inactividad
      startWith(false) // Al inicio hay actividad
    );

    this.#visibleProducts$.pipe(
      bufferWhen(() => noActivity$.pipe(filter(inactive => inactive))), // Espera a que haya inactividad
      filter(productGroups => productGroups.length > 0), // Evita procesar arrays vacíos
      mergeMap(productGroups => productGroups.flat()), // 🔄 Aplana el array → number[]
      scan((acc: number[], productId: number) => [...new Set([...acc, productId])], []), // 📌 Acumula productos únicos
      auditTime(500), // ⏳ Espera estabilidad antes de hacer la petición
      switchMap(productIds => {
        const idsToFetch = productIds.filter(id => !this.productDetails().has(id));
        return idsToFetch.length > 0 ? this.#productsService.getProductsDetailsByIds(idsToFetch) : of({});
      })
    ).subscribe(details => {
      this.productDetails.update(prev => {
        const updatedMap = new Map(prev); // Clonar el Map existente
        Object.entries(details).forEach(([index, detail]) => {
          const productDetail: Product = detail as Product;
          updatedMap.set(Number(productDetail.id), productDetail as Product); // Convertir id a número y agregarlo
        });
        return updatedMap;
      });
    });
  }

  onProductCardReady(productId: number) {
    if (this.productDetails().has(productId)) return;
    else this.#visibleProducts$.next([productId]);
  }

  onProductCardHover(productId: number) {
    if (this.productDetails().has(productId)) return;
    else this.#visibleProducts$.next([productId]);
  }

  removeChipFilter(key: string) {
    if (key && this.filters().has(key)) {
      const type = this.filters().get(key)?.type;
      if (!type) return;

      this.signalsStore.setFilterChipRemoved(key);
    }
  }
}
