<section class="signup-content">

  <!-- image -->
  <img src="/assets/images/signup/background.webp" class="background-img" alt="Signup image">

  <div class="signup">

    <!-- Header -->
    <div class="signup__header animated fadeInDown">
      <div class="text-center">

        <a routerLink="/shop" class="float-start">
          <img class="signup__logo" src="/assets/images/logo.webp?v={{logoVersionNumber()}}" alt="Produce the box">
        </a>

      </div>
    </div>

    <!-- Forms -->
    <section class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 offset-lg-4 signup__body animated fadeInUp">

        <!-- Loading -->
        @if (isLoadingContent() || accountService.isLoading() || isWaitingResponse()) {
        <mat-progress-bar mode="indeterminate" value="accent"></mat-progress-bar>
        }

        <header class="text-center">
          <h1 class="h4-bold text-secondary-800 head-font">
            CREATE YOUR ACCOUNT
          </h1>
        </header>

        <mat-stepper linear labelPosition="bottom" #stepper>

          <!-- Delivery information -->
          <mat-step #firstStep [completed]="firstStep.completed" [editable]="false">

            <ng-template matStepLabel>
              Delivery Information
            </ng-template>

            <app-check-address-signup [firstStep]="firstStep"
              (deliveryAddressChanged)="deliveryAddressInformation = $event"
              (selectedDayChanged)="deliverySelectedDay = $event"
              (selectedTimeWindowChanged)="deliverySelectedTimeWindow = $event"
              (deliveryOptionChanged)="deliveryOptionSelected = $event" (onNextStep)="continue()">
            </app-check-address-signup>

            <footer class="d-flex gap-2 flex-wrap mt-3 justify-content-center text-secondary">
              <span class="h6-regular">Already have an account?</span>
              <span class="h6-bold cursor-pointer" [routerLink]="'/login'">Sign in now</span>
            </footer>

          </mat-step>

          <!-- Personal information -->
          <mat-step [completed]="formValidityPersonalInformation && firstStep.completed" [editable]="false">

            <ng-template matStepLabel>
              Personal Information
            </ng-template>

            <app-personal-information-signup [stepper]="matStepper()" [firstStep]="firstStep"
              #personalInformationComponent [creatingAccount]="shouldDisableCreateClientButton()"
              (formValidityChanged)="formValidityPersonalInformation = $event"
              (personalInformationChanged)="personalInformationData = $event" (onNextStep)="createNewClient()">
            </app-personal-information-signup>

          </mat-step>

          <!-- Payment method information -->
          <mat-step [completed]="formValidityPaymentMethod && !isWaitingResponse()" [editable]="false"
            [stepControl]="paymentMethodStepControl">

            <ng-template matStepLabel>
              Payment Information
            </ng-template>

            <app-payment-method-signup (formValidityChanged)="formValidityPaymentMethod = $event"
              (onNextStep)="showWelcomeModal()" [in_selectedMembership]="deliveryOptionSelected">
            </app-payment-method-signup>

          </mat-step>

        </mat-stepper>

      </div>
    </section>

  </div>

</section>